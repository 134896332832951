import { Dispatch } from 'react';
import { Site } from '../../../constants/Common';
import { Leave } from '../../../constants/Leave';
import { LeaveNote } from '../../../constants/Note';
import { Timesheet } from '../../../constants/Timesheet/Timesheet';
import { Action } from '../../../constants/TypescriptUtilities';

export type MyTimeState = {
	timesheetID: string | null;
	leaveID: string | null;
	startDate: Date;
	endDate: Date;
	sites: Record<string, Site>;
	currentTimesheets: Record<string, Timesheet>;
	loadingCurrentTimesheets: boolean;
	timesheetsHistory: Record<string, Timesheet>;
	loadingTimesheetsHistory: boolean;
	leave: Record<string, Leave>;
	loadingLeave: boolean;
	leaveNotes: Record<string, LeaveNote>;
	loadingLeaveNotes: boolean;
};

export type MyTimeOutletContext = {
	context: MyTimeState;
	dispatch: Dispatch<MyTimeActions>;
};

type MyTimeAction<
	T extends MyTimeActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum MyTimeActionTypes {
	SET_TIMESHEET_ID = 'SET_TIMESHEET_ID',
	SET_LEAVE_ID = 'SET_LEAVE_ID',
	SET_START_DATE = 'SET_START_DATE',
	SET_END_DATE = 'SET_END_DATE',
	SET_SITES = 'SET_SITES',
	SET_CURRENT_TIMESHEETS = 'SET_CURRENT_TIMESHEETS',
	SET_LOADING_CURRENT_TIMESHEETS = 'SET_LOADING_CURRENT_TIMESHEETS',
	SET_TIMESHEETS_HISTORY = 'SET_TIMESHEETS_HISTORY',
	SET_LOADING_TIMESHEETS_HISTORY = 'SET_LOADING_TIMESHEETS_HISTORY',
	SET_LEAVE = 'SET_LEAVE',
	SET_LOADING_LEAVE = 'SET_LOADING_LEAVE',
	ADD_LEAVE_NOTE = 'ADD_LEAVE_NOTES',
	SET_LOADING_LEAVE_NOTES = 'SET_LOADING_LEAVE_NOTES',
}

type SetTimesheetIDAction = MyTimeAction<
	MyTimeActionTypes.SET_TIMESHEET_ID,
	{
		timesheetID: MyTimeState['timesheetID'];
	}
>;

type SetLeaveIDAction = MyTimeAction<
	MyTimeActionTypes.SET_LEAVE_ID,
	{
		leaveID: MyTimeState['leaveID'];
	}
>;

type SetStartDateAction = MyTimeAction<
	MyTimeActionTypes.SET_START_DATE,
	{
		startDate: MyTimeState['startDate'];
	}
>;

type SetEndDateAction = MyTimeAction<
	MyTimeActionTypes.SET_END_DATE,
	{
		endDate: MyTimeState['endDate'];
	}
>;

type SetSitesAction = MyTimeAction<
	MyTimeActionTypes.SET_SITES,
	{
		sites: MyTimeState['sites'];
	}
>;

type SetCurrentTimesheetsAction = MyTimeAction<
	MyTimeActionTypes.SET_CURRENT_TIMESHEETS,
	{
		currentTimesheets: MyTimeState['currentTimesheets'];
	}
>;

type SetLoadingCurrentTimesheetsAction = MyTimeAction<
	MyTimeActionTypes.SET_LOADING_CURRENT_TIMESHEETS,
	{
		loadingCurrentTimesheets: MyTimeState['loadingCurrentTimesheets'];
	}
>;

type SetTimesheetsHistoryAction = MyTimeAction<
	MyTimeActionTypes.SET_TIMESHEETS_HISTORY,
	{
		timesheetsHistory: MyTimeState['timesheetsHistory'];
	}
>;

type SetLoadingTimesheetsHistoryAction = MyTimeAction<
	MyTimeActionTypes.SET_LOADING_TIMESHEETS_HISTORY,
	{
		loadingTimesheetsHistory: MyTimeState['loadingTimesheetsHistory'];
	}
>;

type SetLeaveAction = MyTimeAction<
	MyTimeActionTypes.SET_LEAVE,
	{
		leave: MyTimeState['leave'];
	}
>;

type SetLoadingLeaveAction = MyTimeAction<
	MyTimeActionTypes.SET_LOADING_LEAVE,
	{
		loadingLeave: MyTimeState['loadingLeave'];
	}
>;

type AddLeaveNotesAction = MyTimeAction<
	MyTimeActionTypes.ADD_LEAVE_NOTE,
	{
		leaveNotes: MyTimeState['leaveNotes'];
	}
>;

type SetLoadingLeaveNotesAction = MyTimeAction<
	MyTimeActionTypes.SET_LOADING_LEAVE_NOTES,
	{
		loadingLeaveNotes: MyTimeState['loadingLeaveNotes'];
	}
>;

export type MyTimeActions =
	| SetTimesheetIDAction
	| SetLeaveIDAction
	| SetStartDateAction
	| SetEndDateAction
	| SetSitesAction
	| SetCurrentTimesheetsAction
	| SetLoadingCurrentTimesheetsAction
	| SetTimesheetsHistoryAction
	| SetLoadingTimesheetsHistoryAction
	| SetLeaveAction
	| SetLoadingLeaveAction
	| AddLeaveNotesAction
	| SetLoadingLeaveNotesAction;

export const setTimesheetID = (
	timesheetID: MyTimeState['timesheetID'],
): SetTimesheetIDAction => ({
	type: MyTimeActionTypes.SET_TIMESHEET_ID,
	payload: { timesheetID },
});

export const setLeaveID = (
	leaveID: MyTimeState['leaveID'],
): SetLeaveIDAction => ({
	type: MyTimeActionTypes.SET_LEAVE_ID,
	payload: { leaveID },
});

export const setStartDate = (
	startDate: MyTimeState['startDate'],
): SetStartDateAction => ({
	type: MyTimeActionTypes.SET_START_DATE,
	payload: { startDate },
});

export const setEndDate = (
	endDate: MyTimeState['endDate'],
): SetEndDateAction => ({
	type: MyTimeActionTypes.SET_END_DATE,
	payload: { endDate },
});

export const setSites = (sites: MyTimeState['sites']): SetSitesAction => ({
	type: MyTimeActionTypes.SET_SITES,
	payload: { sites },
});

export const setCurrentTimesheets = (
	currentTimesheets: MyTimeState['currentTimesheets'],
): SetCurrentTimesheetsAction => ({
	type: MyTimeActionTypes.SET_CURRENT_TIMESHEETS,
	payload: {
		currentTimesheets,
	},
});

export const setLoadingCurrentTimesheets = (
	loadingCurrentTimesheets: MyTimeState['loadingCurrentTimesheets'],
): SetLoadingCurrentTimesheetsAction => ({
	type: MyTimeActionTypes.SET_LOADING_CURRENT_TIMESHEETS,
	payload: {
		loadingCurrentTimesheets,
	},
});

export const setTimesheetsHistory = (
	timesheetsHistory: MyTimeState['timesheetsHistory'],
): SetTimesheetsHistoryAction => ({
	type: MyTimeActionTypes.SET_TIMESHEETS_HISTORY,
	payload: {
		timesheetsHistory,
	},
});

export const setLoadingTimesheetsHistory = (
	loadingTimesheetsHistory: MyTimeState['loadingTimesheetsHistory'],
): SetLoadingTimesheetsHistoryAction => ({
	type: MyTimeActionTypes.SET_LOADING_TIMESHEETS_HISTORY,
	payload: {
		loadingTimesheetsHistory,
	},
});

export const setLeave = (leave: MyTimeState['leave']): SetLeaveAction => ({
	type: MyTimeActionTypes.SET_LEAVE,
	payload: {
		leave,
	},
});

export const setLoadingLeave = (
	loadingLeave: MyTimeState['loadingLeave'],
): SetLoadingLeaveAction => ({
	type: MyTimeActionTypes.SET_LOADING_LEAVE,
	payload: {
		loadingLeave,
	},
});

export const addLeaveNote = (
	leaveNotes: Record<string, LeaveNote>,
): AddLeaveNotesAction => {
	return {
		type: MyTimeActionTypes.ADD_LEAVE_NOTE,
		payload: { leaveNotes },
	};
};

export const setLoadingLeaveNotes = (
	loadingLeaveNotes: MyTimeState['loadingLeaveNotes'],
): SetLoadingLeaveNotesAction => ({
	type: MyTimeActionTypes.SET_LOADING_LEAVE_NOTES,
	payload: {
		loadingLeaveNotes,
	},
});
