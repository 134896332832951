import { Box, Button } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Dispatch, useEffect, useState } from 'react';
import { UserDetails } from '../../../constants/Common';
import { Leave } from '../../../constants/Leave';
import { LeaveNote } from '../../../constants/Note';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { MyTimeState } from '../StateManagement/actions';
import { CurrentLeaveTableWrapper } from './CurrentLeaveTableWrapper';

type CurrentLeaveTableData = {
	id: Leave['id'];
	leavePeriod: string;
	duration: string;
	leaveType: Leave['leaveType'];
	note: LeaveNote['note'];
};

export type CurrentLeaveProps = {
	userDetails: UserDetails;
	selected: MyTimeState['leaveID'];
	setSelected: (leaveID: string) => void;
	endDate: Date;
	setEndDate: Dispatch<Date>;
	leave: MyTimeState['leave'];
	loadingLeave: boolean;
	leaveNotes: MyTimeState['leaveNotes'];
	loadingLeaveNotes: boolean;
};

export const CurrentLeave = ({
	leave,
	leaveNotes,
	endDate,
	setEndDate,
	selected,
	setSelected,
	loadingLeave,
	loadingLeaveNotes,
}: CurrentLeaveProps): JSX.Element => {
	const [tableData, setTableData] = useState<CurrentLeaveTableData[]>([]);
	const tableTitle = 'Current Leave';

	useEffect(() => {
		if (!loadingLeave && !loadingLeaveNotes) {
			const tableData: CurrentLeaveTableData[] = Object.keys(leave).map(
				(leaveID) => {
					const leaveItem = leave[leaveID];
					const note = leaveNotes[leaveID] ?? '';
					const startDate = leaveItem.startDate.toDate();
					const endDate = leaveItem.endDate?.toDate() ?? null;

					return {
						id: leaveItem.id,
						leavePeriod: !endDate
							? `${formatSlashedDate(startDate)}`
							: `${formatSlashedDate(startDate)} - 
										${formatSlashedDate(endDate)}`,
						duration: !endDate
							? `${leaveItem.hours} hours`
							: `${differenceInDays(endDate, startDate)} days`,
						leaveType: leaveItem.leaveType,
						note: note.note,
					};
				},
			);

			setTableData(tableData);
		}
	}, [loadingLeave, loadingLeaveNotes, leave, leaveNotes]);

	const renderTableOption = (leaveID: Leave['id']): JSX.Element => (
		<Box display="flex" justifyContent="center">
			<Button
				variant="outlined"
				onClick={(): void => console.log(leaveID)}>
				Options
			</Button>
		</Box>
	);

	const cellWidth = '20%';
	const columns: MUIDataTableColumnDef[] = [
		{
			label: 'Leave Period',
			name: 'leavePeriod',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Duration',
			name: 'duration',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Type',
			name: 'leaveType',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'My Note',
			name: 'note',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				sort: false,
			},
		},
		{
			label: 'Options',
			name: 'id',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				sort: false,
				customBodyRender: renderTableOption,
			},
		},
	];

	const tableLoadingOpt: MUIDataTableOptions = {
		textLabels: {
			body: {
				noMatch:
					loadingLeave || loadingLeaveNotes ? (
						<LoadingDots />
					) : (
						'No leave found'
					),
			},
		},
		tableBodyHeight: 'calc(100vh - 298px)',
		print: false,
		filter: false,
	};

	return (
		<Box flex="1">
			<CurrentLeaveTableWrapper>
				<DateDataTableWithID
					title={tableTitle}
					tableData={tableData}
					columns={columns}
					date={[endDate, setEndDate]}
					selection={[selected, setSelected]}
					customTableOptions={tableLoadingOpt}
					allowFuture
					weekEnding
					showWeekRange={false}
				/>
			</CurrentLeaveTableWrapper>
		</Box>
	);
};
