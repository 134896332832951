/* eslint-disable id-length */
// it's a copout to disable this rule, but for generic sorting helpers, I can only think of less helpful names than `a` and `b`
import { ExplicitAny } from '../../constants/AnyTypes';
import { MinimalInterface, Minimal } from '../../constants/Common';
import { Timestamp } from '../../firebase/firebase';

export const sortMinimal = <T extends MinimalInterface>(
	order: 'asc' | 'desc',
): ((
	minimalA: {
		data: Minimal<T> | null;
	},
	minimalB: {
		data: Minimal<T> | null;
	},
) => number) => {
	const multiplier = order === 'asc' ? -1 : 1;
	return (
		minimalA: { data: Minimal<T> | null },
		minimalB: { data: Minimal<T> | null },
	): number => {
		const a = minimalA.data;
		const b = minimalB.data;

		if (a === null) {
			return -1 * multiplier;
		} else if (b === null) {
			return multiplier;
		}
		return a.name.localeCompare(b.name) * multiplier;
	};
};

export const sortObjectByKeys = <T>(
	object: Record<string, T>,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(object).sort(([a], [b]) =>
			a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
		),
	);

export const sortObjectByValues = (
	object: Record<string, string>,
): Record<string, string> =>
	Object.fromEntries(
		Object.entries(object).sort(([, a], [, b]) =>
			a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
		),
	);

export const sortObjectByField = <
	Field extends string,
	T extends Record<Field, string>,
>(
	unsortedObject: Record<string, T>,
	field: Field,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(unsortedObject).sort(([, a], [, b]) =>
			a[field]
				.toLocaleLowerCase()
				.localeCompare(b[field].toLocaleLowerCase()),
		),
	);

export type GroupBy<Order extends string[], Option> = {
	getGroup: (option: Option) => Order[number];
	order: Order;
};

export const sortObjectByGroupBy = <
	T extends Record<string, ExplicitAny>,
	Order extends string[],
>(
	unsortedObject: Record<string, T>,
	groupBy: (value: T) => Order[number],
	groupByOrder: Order,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(unsortedObject).sort(
			([, a], [, b]) =>
				groupByOrder.indexOf(groupBy(a)) -
				groupByOrder.indexOf(groupBy(b)),
		),
	);

export const sortObjectBySubField = <
	Field extends string,
	SubField extends string,
	T extends Record<Field, Record<SubField, string>>,
>(
	unsortedObject: Record<string, T>,
	field: Field,
	subfield: SubField,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(unsortedObject).sort(([, a], [, b]) =>
			a[field][subfield]
				.toLocaleLowerCase()
				.localeCompare(b[field][subfield].toLocaleLowerCase()),
		),
	);

/**
 * Case insensitive sorting of string arrays.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortStringArray = <T extends string>(list: T[]): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
	);
	return sortedList;
};

/**
 * Case insensitive sorting of lists.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortByField = <
	Field extends string,
	T extends Record<Field, string>,
>(
	list: T[],
	field: Field,
): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a[field]
			.toLocaleLowerCase()
			.localeCompare(b[field].toLocaleLowerCase()),
	);
	return sortedList;
};

/**
 * Case insensitive sorting of lists on subfields.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortBySubField = <
	Field extends string,
	SubField extends string,
	T extends Record<Field, Record<SubField, string>>,
>(
	list: T[],
	field: Field,
	subfield: SubField,
): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a[field][subfield].localeCompare(b[field][subfield]),
	);
	return sortedList;
};

/**
 * Sorts an array of objects by a timestamp field.
 */
export const sortByTimestampField = <
	T extends Record<Field, Timestamp>,
	Field extends keyof T,
>(
	list: T[],
	field: Field,
	order: 'asc' | 'desc' = 'asc',
): T[] => {
	const sortedList = [...list];
	const multiplier = order === 'asc' ? 1 : -1;

	sortedList.sort((a, b) => {
		const timeDiff =
			(a[field].toDate().getTime() - b[field].toDate().getTime()) *
			multiplier;

		return timeDiff;
	});

	return sortedList;
};

/**
 * Sorts a record of objects by a timestamp field.
 */
export const sortObjectByTimestampField = <
	T extends Record<string, Record<Field, Timestamp>>,
	Field extends keyof T[keyof T],
>(
	record: T,
	field: Field,
	order: 'asc' | 'desc' = 'asc',
): T => {
	const entries = Object.entries(record);
	const multiplier = order === 'asc' ? 1 : -1;

	entries.sort(([_, valueA], [__, valueB]) => {
		const timeDiff =
			(valueA[field].toDate().getTime() -
				valueB[field].toDate().getTime()) *
			multiplier;

		return timeDiff;
	});

	return Object.fromEntries(entries) as T;
};
