import { startOfWeek } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { UserProps } from '../../../constants/Common';
import firebaseApi from '../../../firebase/firebaseApi';
import { useMyTimeSearchParams } from '../hooks/MyTimeNavHooks';
import { useMyTimeContext } from '../MyTimeWrapper';
import { setLoadingTimesheetsHistory } from '../StateManagement/actions';
import { TimesheetsHistory } from './TimesheetsHistory';

export const TimesheetsHistoryWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	const { context, dispatch } = useMyTimeContext();
	const { setMyTimeSearchParams } = useMyTimeSearchParams();

	// this useEffect is used to clear the leaveID from the url search param
	useEffect(() => {
		setMyTimeSearchParams({
			timesheetID: context.timesheetID ?? undefined,
		});
	}, [context.timesheetID, setMyTimeSearchParams]);

	return (
		<TimesheetsHistory
			userDetails={userDetails}
			selected={context.timesheetID}
			setSelected={(timesheetID): void =>
				setMyTimeSearchParams({
					timesheetID,
				})
			}
			endDate={context.endDate}
			setEndDate={(endDate): void =>
				setMyTimeSearchParams({
					endDate,
					startDate: startOfWeek(endDate),
				})
			}
			timesheets={context.timesheetsHistory}
			loading={context.loadingTimesheetsHistory}
			setLoading={useCallback(
				(value) => dispatch(setLoadingTimesheetsHistory(value)),
				[dispatch],
			)}
			firebaseApi={firebaseApi}
		/>
	);
};
