import { startOfWeek } from 'date-fns';
import { useEffect } from 'react';
import { UserProps } from '../../../constants/Common';
import { Timesheet } from '../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../constants/Timesheet/TimesheetStatus';
import firebaseApi from '../../../firebase/firebaseApi';
import { formatSearchParamsDate } from '../../helpers/dateFormatters';
import { useMyTimeSearchParams } from '../hooks/MyTimeNavHooks';
import { useMyTimeContext } from '../MyTimeWrapper';
import { CurrentTimesheets } from './CurrentTimesheets';

export const CurrentTimesheetsWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	const { context } = useMyTimeContext();
	const { searchParams, setMyTimeSearchParams } = useMyTimeSearchParams();

	// this useEffect is used to clear the leaveID from the url search param
	useEffect(() => {
		setMyTimeSearchParams({
			timesheetID: context.timesheetID ?? undefined,
		});
	}, [context.timesheetID, setMyTimeSearchParams]);

	const navigateToTimesheet = (
		targetTimesheet: Pick<
			Timesheet,
			'id' | 'weekEnding' | 'timesheetStatus'
		>,
	): void => {
		const params = new URLSearchParams(searchParams);
		params.set(
			'endDate',
			formatSearchParamsDate(targetTimesheet.weekEnding.toDate()),
		);
		params.set('timesheetID', targetTimesheet.id);

		const page =
			targetTimesheet.timesheetStatus === TimesheetStatus.Approved ||
			targetTimesheet.timesheetStatus === TimesheetStatus.Archived
				? 'timesheets-history'
				: 'current-timesheets';

		// As we cannot change both timesheetID and weekEnding at the same time
		// navigate to page directly via the window interface
		const hrefDuplicateTimesheet = `/my-time/${page}?${params}`;
		const openResult = window.open(hrefDuplicateTimesheet, '_blank');
		// if popup is blocked, redirect to the page
		if (openResult === null) {
			window.location.href = hrefDuplicateTimesheet;
		}
	};

	return (
		<CurrentTimesheets
			userDetails={userDetails}
			selected={context.timesheetID}
			setSelected={(timesheetID): void =>
				setMyTimeSearchParams({
					timesheetID,
				})
			}
			endDate={context.endDate}
			setEndDate={(endDate): void =>
				setMyTimeSearchParams({
					endDate,
					startDate: startOfWeek(endDate),
				})
			}
			sites={context.sites}
			timesheets={context.currentTimesheets}
			timesheetsHistory={context.timesheetsHistory}
			navigateToTimesheet={navigateToTimesheet}
			loading={context.loadingCurrentTimesheets}
			firebaseApi={firebaseApi}
		/>
	);
};
