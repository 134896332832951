import { Box } from '@mui/material';
import { UserDetails } from '../../../constants/Common';
import { Timesheet } from '../../../constants/Timesheet/Timesheet';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { TimesheetDisplay } from '../../Timesheets/TimesheetDisplay';

type SingleTimesheetViewDeprecatedFirebasecalls =
	| 'getCompany'
	| 'getSite'
	| 'activitySubscription'
	| 'updateTimesheetActivities'
	| 'deleteActivities'
	| 'deleteTimesheet'
	| 'setActivities'
	| 'getDownloadUrl'
	| 'preApproveTimesheet';

type SingleTimesheetViewDeprecatedProps = {
	userDetails: UserDetails;
	displayType: MyTimesheetsDisplayType;
	timesheet: Timesheet | undefined;
	weekEnding: [Date, (endDate: Date) => void];
	loading: boolean;
	firebaseApi: Pick<FirebaseApi, SingleTimesheetViewDeprecatedFirebasecalls>;
};

/**
 * @deprecated This is a wrapper for the old TimesheetDisplay view and should not be used
 */
export const SingleTimesheetViewDeprecated = ({
	userDetails,
	displayType,
	timesheet,
	weekEnding,
	loading,
	firebaseApi,
}: SingleTimesheetViewDeprecatedProps): JSX.Element => {
	return (
		<Box flex="1">
			{!loading && timesheet && (
				<TimesheetDisplay
					timesheet={timesheet}
					userDetails={userDetails}
					type={displayType}
					weekEnding={weekEnding}
					firebaseApi={firebaseApi}
				/>
			)}
		</Box>
	);
};

/**
 * @deprecated This is an old timesheets display view type and should not be used
 */
export type MyTimesheetsDisplayType = 'history' | 'current';
