import { Box, Tabs, Tab } from '@mui/material';
import { startOfWeek } from 'date-fns';
import { useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { UserDetails } from '../../constants/Common';
import { LeaveNote } from '../../constants/Note';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { useMyTimeSearchParams } from './hooks/MyTimeNavHooks';
import { MyTimeState } from './StateManagement/actions';

export type MyTimeFirebaseCalls =
	| 'activeSitesByCompanySubscription'
	| 'timesheetsRecordByWeekUserStatus'
	| 'subLeaveByUser'
	| 'subLeaveNotesByLeaveID'
	| 'subLeaveNotesByUserID';

export type MyTimeProps = {
	userDetails: UserDetails;
	weekEnding: Date;
	leave: MyTimeState['leave'];
	setSites: (sites: MyTimeState['sites']) => void;
	setCurrentTimesheets: (
		currentTimesheets: MyTimeState['currentTimesheets'],
	) => void;
	setLoadingCurrentTimesheets: (loading: boolean) => void;
	setTimesheetsHistory: (
		timesheetsHistory: MyTimeState['timesheetsHistory'],
	) => void;
	setLoadingTimesheetHistory: (loading: boolean) => void;
	setLeave: (leave: MyTimeState['leave']) => void;
	setLoadingLeave: (loading: boolean) => void;
	addLeaveNote: (leaveNotes: MyTimeState['leaveNotes']) => void;
	setLoadingLeaveNotes: (loading: boolean) => void;
	firebaseApi: Pick<FirebaseApi, MyTimeFirebaseCalls>;
};

export const MyTime = ({
	userDetails,
	weekEnding,
	leave,
	setSites,
	setCurrentTimesheets,
	setLoadingCurrentTimesheets,
	setTimesheetsHistory,
	setLoadingTimesheetHistory,
	setLeave,
	setLoadingLeave,
	addLeaveNote: addLeaveNote,
	setLoadingLeaveNotes,
	firebaseApi,
}: MyTimeProps): JSX.Element => {
	const { searchParams } = useMyTimeSearchParams();
	const pathMatch = useMatch('/my-time/:tab');

	const titles = [
		'current timesheets',
		'timesheets history',
		'current leave',
		'leave history',
	];

	useEffect(() => {
		return firebaseApi.activeSitesByCompanySubscription(
			userDetails.companyID,
			(sites) => {
				setSites(sites);
			},
		);
	}, [firebaseApi, setSites, userDetails.companyID]);

	useEffect(() => {
		const weekStart = startOfWeek(weekEnding);
		return firebaseApi.timesheetsRecordByWeekUserStatus(
			weekStart,
			weekEnding,
			userDetails.userID,
			[
				TimesheetStatus.Submitted,
				TimesheetStatus.Approved,
				TimesheetStatus.Active,
			],
			(timesheets: Record<string, Timesheet>): void => {
				setCurrentTimesheets(timesheets);
				setLoadingCurrentTimesheets(false);
			},
		);
	}, [
		firebaseApi,
		setCurrentTimesheets,
		setLoadingCurrentTimesheets,
		userDetails.userID,
		weekEnding,
	]);

	useEffect(() => {
		const weekStart = startOfWeek(weekEnding);
		return firebaseApi.timesheetsRecordByWeekUserStatus(
			weekStart,
			weekEnding,
			userDetails.userID,
			[TimesheetStatus.Approved, TimesheetStatus.Archived],
			(timesheets) => {
				setTimesheetsHistory(timesheets);
				setLoadingTimesheetHistory(false);
			},
		);
	}, [
		firebaseApi,
		setLoadingTimesheetHistory,
		setTimesheetsHistory,
		userDetails.userID,
		weekEnding,
	]);

	useEffect(() => {
		return firebaseApi.subLeaveByUser(userDetails.userID, (leave): void => {
			setLeave(leave);
			setLoadingLeave(false);
		});
	}, [firebaseApi, setLeave, setLoadingLeave, userDetails.userID]);

	useEffect(() => {
		const leaveIDs: string[] = Object.keys(leave);

		if (leaveIDs.length === 0) {
			setLoadingLeaveNotes(false);
			return;
		}

		const activeSubscriptions: (() => void)[] = [];
		let pendingSubscriptions = leaveIDs.length;
		setLoadingLeaveNotes(true);

		leaveIDs.forEach((leaveID) => {
			const unsubscribe = firebaseApi.subLeaveNotesByUserID(
				userDetails.userID,
				leaveID,
				(userLeaveNotes: LeaveNote[]) => {
					const leaveNote = userLeaveNotes[0];
					if (leaveNote) {
						addLeaveNote({ [leaveID]: leaveNote });
					}
					pendingSubscriptions -= 1;
					if (pendingSubscriptions === 0) {
						setLoadingLeaveNotes(false);
					}
				},
			);
			activeSubscriptions.push(unsubscribe);
		});

		return () => {
			activeSubscriptions.forEach((unsubscribe) => unsubscribe());
		};
	}, [
		leave,
		userDetails.userID,
		firebaseApi,
		setLoadingLeaveNotes,
		addLeaveNote,
	]);

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};
