import {
	sortObjectByField,
	sortObjectByTimestampField,
} from '../../helpers/sortHelpers';
import { MyTimeActions, MyTimeActionTypes, MyTimeState } from './actions';

export const createInitialMyTimeState = (initArg: {
	startDate: Date;
	endDate: Date;
}): MyTimeState => ({
	timesheetID: null,
	leaveID: null,
	startDate: initArg.startDate,
	endDate: initArg.endDate,
	sites: {},
	currentTimesheets: {},
	loadingCurrentTimesheets: true,
	timesheetsHistory: {},
	loadingTimesheetsHistory: true,
	leave: {},
	loadingLeave: true,
	leaveNotes: {},
	loadingLeaveNotes: true,
});

export const myTimeReducer = (
	state: MyTimeState,
	action: MyTimeActions,
): MyTimeState => {
	switch (action.type) {
		case MyTimeActionTypes.SET_TIMESHEET_ID: {
			return {
				...state,
				timesheetID: action.payload.timesheetID,
			};
		}

		case MyTimeActionTypes.SET_LEAVE_ID: {
			return {
				...state,
				leaveID: action.payload.leaveID,
			};
		}

		case MyTimeActionTypes.SET_START_DATE: {
			return {
				...state,
				startDate: action.payload.startDate,
			};
		}

		case MyTimeActionTypes.SET_END_DATE: {
			return {
				...state,
				endDate: action.payload.endDate,
			};
		}

		case MyTimeActionTypes.SET_SITES: {
			return {
				...state,
				sites: sortObjectByField(action.payload.sites, 'name'),
			};
		}

		case MyTimeActionTypes.SET_CURRENT_TIMESHEETS: {
			return {
				...state,
				currentTimesheets: sortObjectByTimestampField(
					action.payload.currentTimesheets,
					'weekEnding',
				),
			};
		}

		case MyTimeActionTypes.SET_LOADING_CURRENT_TIMESHEETS: {
			return {
				...state,
				loadingCurrentTimesheets:
					action.payload.loadingCurrentTimesheets,
			};
		}

		case MyTimeActionTypes.SET_TIMESHEETS_HISTORY: {
			return {
				...state,
				timesheetsHistory: sortObjectByTimestampField(
					action.payload.timesheetsHistory,
					'weekEnding',
				),
			};
		}

		case MyTimeActionTypes.SET_LOADING_TIMESHEETS_HISTORY: {
			return {
				...state,
				loadingTimesheetsHistory:
					action.payload.loadingTimesheetsHistory,
			};
		}

		case MyTimeActionTypes.SET_LEAVE: {
			return {
				...state,
				leave: sortObjectByTimestampField(
					action.payload.leave,
					'startDate',
					'desc',
				),
			};
		}

		case MyTimeActionTypes.SET_LOADING_LEAVE: {
			return {
				...state,
				loadingLeave: action.payload.loadingLeave,
			};
		}

		case MyTimeActionTypes.ADD_LEAVE_NOTE: {
			return {
				...state,
				leaveNotes: {
					...state.leaveNotes,
					...action.payload.leaveNotes,
				},
			};
		}

		case MyTimeActionTypes.SET_LOADING_LEAVE_NOTES: {
			return {
				...state,
				loadingLeaveNotes: action.payload.loadingLeaveNotes,
			};
		}

		default:
			return state;
	}
};
