import { UserProps } from '../../constants/Common';
import { useFeatureFlagContext } from '../../providers/featureFlags/Provider';
import { MyTimesheetsDeprecated } from './MyTimesheets/MyTimesheetsDeprecated';
import { MyTimeWrapper } from './MyTimeWrapper';

export const MyTimesheetsWrapper = (userProps: UserProps): JSX.Element => {
	const featureFlags = useFeatureFlagContext();
	const enableMyLeave = featureFlags.get('enableMyLeave');

	return (
		<>
			{enableMyLeave ? (
				<MyTimeWrapper {...userProps} />
			) : (
				<MyTimesheetsDeprecated {...userProps} />
			)}
		</>
	);
};
