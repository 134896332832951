import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatSearchParamsDate } from '../../helpers/dateFormatters';

enum MyTimeSearchParamKey {
	endDate = 'endDate',
	startDate = 'startDate',
	timesheetID = 'timesheetID',
	leaveID = 'leaveID',
}

type MyTimeSearchParams = {
	endDate?: Date;
	startDate?: Date;
	timesheetID?: string;
	leaveID?: string;
};

export const useMyTimeSearchParams = (): {
	searchParams: URLSearchParams;
	setMyTimeSearchParams: (params: MyTimeSearchParams) => void;
} => {
	const [searchParams, setSearchParams] = useSearchParams();

	const setSearchParamsCallback = useCallback(
		(params: MyTimeSearchParams): void => {
			setSearchParams((prev) => {
				if (params.startDate) {
					prev.set(
						MyTimeSearchParamKey.startDate,
						formatSearchParamsDate(params.startDate),
					);
				}
				if (params.endDate) {
					prev.set(
						MyTimeSearchParamKey.endDate,
						formatSearchParamsDate(params.endDate),
					);
				}
				if (params.timesheetID) {
					prev.set(
						MyTimeSearchParamKey.timesheetID,
						params.timesheetID,
					);
					prev.delete(MyTimeSearchParamKey.leaveID);
				}
				if (params.leaveID) {
					prev.set(MyTimeSearchParamKey.leaveID, params.leaveID);
					prev.delete(MyTimeSearchParamKey.timesheetID);
				}
				return prev;
			});
		},
		[setSearchParams],
	);

	return { searchParams, setMyTimeSearchParams: setSearchParamsCallback };
};
