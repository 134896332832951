import { Box, Divider, Stack } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { UserDetails } from '../../../constants/Common';
import { Timesheet } from '../../../constants/Timesheet/Timesheet';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../../providers/featureFlags/Provider';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { formatShortDate } from '../../helpers/dateFormatters';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { MyTimeState } from '../StateManagement/actions';
import { SingleTimesheetView } from './SingleTimesheetView';
import { SingleTimesheetViewDeprecated } from './SingleTimesheetViewDeprecated';

type TimesheetsHistoryFirebaseCalls =
	| 'getCompany'
	| 'getSite'
	| 'activitySubscription'
	| 'updateTimesheetActivities'
	| 'deleteActivities'
	| 'deleteTimesheet'
	| 'setActivities'
	| 'getDownloadUrl'
	| 'preApproveTimesheet';

export type TimesheetsHistoryProps = {
	userDetails: UserDetails;
	selected: MyTimeState['timesheetID'];
	setSelected: (timesheetID: string) => void;
	endDate: MyTimeState['endDate'];
	setEndDate: (endDate: Date) => void;
	timesheets: MyTimeState['timesheetsHistory'];
	loading: boolean;
	setLoading: (loading: boolean) => void;
	firebaseApi: Pick<FirebaseApi, TimesheetsHistoryFirebaseCalls>;
};

export const TimesheetsHistory = ({
	userDetails,
	selected,
	setSelected,
	endDate,
	setEndDate,
	timesheets,
	loading,
	setLoading,
	firebaseApi,
}: TimesheetsHistoryProps): JSX.Element => {
	const featureFlags = useFeatureFlagContext();
	const createTimesheetsV3 = featureFlags.get('enableCreateMyTimesheetsV3');

	const cellWidth = '33%';
	const columns: MUIDataTableColumnDef[] = [
		{
			label: 'Site',
			name: 'site',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				customBodyRender: (site: Timesheet['site']) => site.name,
			},
		},
		{
			label: 'Date Submitted',
			name: 'dateSubmitted',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				customBodyRender: (dateSubmitted: Timesheet['dateSubmitted']) =>
					dateSubmitted
						? formatShortDate(dateSubmitted.toDate())
						: '-',
			},
		},
		{
			label: 'Hours Submitted',
			name: 'hours',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				customBodyRender: (hours: Timesheet['hours']) =>
					hours.total.billable,
			},
		},
	];

	const tableLoadingOpt: MUIDataTableOptions = {
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching timesheets found'
				),
			},
		},
		tableBodyHeight: 'calc(100vh - 298px)',
	};

	const handleSetEndDate = (endDate: Date): void => {
		setEndDate(endDate);
		setLoading(true);
	};

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'row',
			}}
			divider={
				<Divider
					sx={{ ml: 1, mr: 1 }}
					orientation="vertical"
					flexItem
				/>
			}
			spacing={2}>
			<Box flex="1">
				<DateDataTableWithID
					title="Timesheets History"
					tableData={Object.values(timesheets)}
					columns={columns}
					date={[endDate, handleSetEndDate]}
					selection={[selected, setSelected]}
					customTableOptions={tableLoadingOpt}
					type="history"
					allowFuture
					weekEnding
					showWeekRange={false}
				/>
			</Box>
			{createTimesheetsV3 ? (
				<SingleTimesheetView />
			) : (
				<SingleTimesheetViewDeprecated
					timesheet={timesheets[selected ?? '']}
					userDetails={userDetails}
					displayType="history"
					weekEnding={[endDate, handleSetEndDate]}
					loading={loading}
					firebaseApi={firebaseApi}
				/>
			)}
		</Stack>
	);
};
