import { endOfWeek, startOfWeek } from 'date-fns';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Outlet, useOutletContext } from 'react-router';
import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import {
	calculateDateWeekRange,
	validateDateString,
} from '../helpers/dateUtilities';
import { useMyTimeSearchParams } from './hooks/MyTimeNavHooks';
import { MyTime } from './MyTime';
import {
	setCurrentTimesheets,
	setSites,
	setTimesheetsHistory,
	setEndDate,
	setStartDate,
	setTimesheetID,
	setLoadingCurrentTimesheets,
	setLoadingTimesheetsHistory,
	MyTimeOutletContext,
	setLeave,
	setLoadingLeave,
	addLeaveNote,
	setLoadingLeaveNotes,
} from './StateManagement/actions';
import {
	myTimeReducer,
	createInitialMyTimeState,
} from './StateManagement/reducer';

export const MyTimeWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const { searchParams, setMyTimeSearchParams } = useMyTimeSearchParams();

	const paramEndDateString = searchParams.get('endDate');
	const paramStartDateString = searchParams.get('startDate');
	const timesheetID = searchParams.get('timesheetID');
	const defaultStartDate = useMemo(() => startOfWeek(new Date()), []);
	const defaultEndDate = useMemo(() => endOfWeek(new Date()), []);

	const [context, dispatch] = useReducer(
		myTimeReducer,
		{
			startDate: defaultStartDate,
			endDate: defaultEndDate,
		},
		createInitialMyTimeState,
	);

	useEffect(() => {
		if (timesheetID) {
			dispatch(setTimesheetID(timesheetID));
		}
	}, [timesheetID]);

	useEffect(() => {
		const updateDateRange = (startDate: Date, endDate: Date): void => {
			dispatch(setStartDate(startDate));
			dispatch(setEndDate(endDate));
		};

		const validatedParamEndDate = validateDateString(paramEndDateString);
		const validatedParamStartDate =
			validateDateString(paramStartDateString);

		let startDate = defaultStartDate;
		let endDate = defaultEndDate;

		if (validatedParamEndDate) {
			const dateRange = calculateDateWeekRange(validatedParamEndDate);
			startDate = dateRange.startDate;
			endDate = dateRange.endDate;
		} else if (validatedParamStartDate) {
			const dateRange = calculateDateWeekRange(validatedParamStartDate);
			startDate = dateRange.startDate;
			endDate = dateRange.endDate;
		}

		updateDateRange(startDate, endDate);
		setMyTimeSearchParams({ startDate, endDate });
	}, [
		defaultEndDate,
		defaultStartDate,
		paramEndDateString,
		paramStartDateString,
		setMyTimeSearchParams,
	]);

	const setSitesHandler = useCallback(
		(value) => dispatch(setSites(value)),
		[],
	);

	const setCurrentTimesheetsHandler = useCallback(
		(value) => dispatch(setCurrentTimesheets(value)),
		[],
	);

	const setLoadingCurrentTimesheetsHandler = useCallback(
		(loading) => dispatch(setLoadingCurrentTimesheets(loading)),
		[],
	);

	const setTimesheetsHistoryHandler = useCallback(
		(value) => dispatch(setTimesheetsHistory(value)),
		[],
	);

	const setLoadingTimesheetHistoryHandler = useCallback(
		(loading) => dispatch(setLoadingTimesheetsHistory(loading)),
		[],
	);

	const setLeaveHandler = useCallback(
		(value) => dispatch(setLeave(value)),
		[],
	);

	const setLoadingLeaveHandler = useCallback(
		(value) => dispatch(setLoadingLeave(value)),
		[],
	);

	const addLeaveNoteHandler = useCallback(
		(leaveNotes) => dispatch(addLeaveNote(leaveNotes)),
		[],
	);

	const setLoadingLeaveNotesHandler = useCallback(
		(loading) => dispatch(setLoadingLeaveNotes(loading)),
		[],
	);

	return (
		<>
			<MyTime
				userDetails={userDetails}
				weekEnding={context.startDate}
				leave={context.leave}
				setSites={setSitesHandler}
				setCurrentTimesheets={setCurrentTimesheetsHandler}
				setLoadingCurrentTimesheets={setLoadingCurrentTimesheetsHandler}
				setTimesheetsHistory={setTimesheetsHistoryHandler}
				setLoadingTimesheetHistory={setLoadingTimesheetHistoryHandler}
				setLeave={setLeaveHandler}
				setLoadingLeave={setLoadingLeaveHandler}
				addLeaveNote={addLeaveNoteHandler}
				setLoadingLeaveNotes={setLoadingLeaveNotesHandler}
				firebaseApi={firebaseApi}
			/>
			<Outlet context={{ context, dispatch }} />
		</>
	);
};

export const useMyTimeContext = (): MyTimeOutletContext =>
	useOutletContext<MyTimeOutletContext>();
