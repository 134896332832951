import { startOfWeek } from 'date-fns';
import { useEffect } from 'react';
import { UserProps } from '../../../constants/Common';
import { useMyTimeSearchParams } from '../hooks/MyTimeNavHooks';
import { useMyTimeContext } from '../MyTimeWrapper';
import { CurrentLeave } from './CurrentLeave';

export const CurrentLeaveWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	const { context } = useMyTimeContext();
	const { setMyTimeSearchParams } = useMyTimeSearchParams();

	// this useEffect is used to clear the leaveID from the url search param
	useEffect(() => {
		setMyTimeSearchParams({
			leaveID: context.leaveID ?? undefined,
		});
	}, [context.leaveID, setMyTimeSearchParams]);

	return (
		<CurrentLeave
			userDetails={userDetails}
			selected={context.leaveID}
			setSelected={(leaveID): void =>
				setMyTimeSearchParams({
					leaveID: leaveID,
				})
			}
			endDate={context.endDate}
			setEndDate={(endDate): void =>
				setMyTimeSearchParams({
					endDate,
					startDate: startOfWeek(endDate),
				})
			}
			leave={context.leave}
			loadingLeave={context.loadingLeave}
			leaveNotes={context.leaveNotes}
			loadingLeaveNotes={context.loadingLeaveNotes}
		/>
	);
};
