import { Box, Card, Typography } from '@mui/material';

export const SingleTimesheetView = (): JSX.Element => {
	return (
		<Box flex="1" data-testid="single-timesheet-view">
			<Card>
				<Typography textAlign="center" height="calc(100vh - 165px)">
					Under Construction 🚧
				</Typography>
			</Card>
		</Box>
	);
};
